// MyActivity.js

import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { 
    faChevronDown, 
    faUserFriends, 
    faCheckCircle, 
    faExchangeAlt, 
    faList, 
    faBell 
} from '@fortawesome/free-solid-svg-icons';

import ItemCard from './ItemCard';
import ItemDetailsModal from './ItemDetailsModal';
import WishListRow from './WishListRow';
import WishDetailsModal from './WishDetailsModal';

import { useUser } from './UserContext';
import useDebounce from './useDebounce';
import { CATEGORIES } from './constants';
import './MyActivity.css';

import { useNotifications } from './NotificationContext'; // Import the Notification Context

function MyActivity() {
    const { userId } = useUser();
    const [activityType, setActivityType] = useState('');
    const [items, setItems] = useState([]);
    const [selectedItem, setSelectedItem] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [searchQuery, setSearchQuery] = useState('');
    const debouncedSearchQuery = useDebounce(searchQuery, 500);
    const [allViewsCollapsed, setAllViewsCollapsed] = useState(true);

    const { notifications, fetchNotifications } = useNotifications(); // Utilize Notification Context

    const [summary, setSummary] = useState({
        itemsWithInterest: 0,
        totalShares: 0,
        approvedRequests: 0,
        totalRequests: 0,
        itemsGiven: 0,
        itemsReceived: 0,
        wishesMatched: 0,
        wishesTotal: 0
    });
    const [scrollPosition, setScrollPosition] = useState(0);
    const containerRef = useRef(null);

    const [currentView, setCurrentView] = useState('');
    const [radioValue, setRadioValue] = useState('');
    const [shouldUpdate, setShouldUpdate] = useState(false);

    // Helper to determine API endpoint and parameters based on activity type
    const getActivityConfig = (activityType) => {
        return {
            'pendingInterests': { status: 'available', owner: 'self', endpoint: '/api/items/activity' },
            'pendingTransfers': { status: 'approved', owner: 'self', endpoint: '/api/items/activity' },
            'approveInterests': { status: 'requested', owner: 'self', endpoint: '/api/items/activity' },
            'transactedShared': { status: 'transferred', owner: 'self', endpoint: '/api/items/activity' },
            'requestsAwaiting': { status: 'requested', owner: 'recipient', endpoint: '/api/items/activity' },
            'requestsApproved': { status: 'approved', owner: 'recipient', endpoint: '/api/items/activity' },
            'transactedReceived': { status: 'transferred', owner: 'recipient', endpoint: '/api/items/activity' },
            'wishesMatched': { status: 'matched', owner: 'self', endpoint: '/api/wishes/activity' },
            'wishesUnmatched': { status: 'unMatched', owner: 'self', endpoint: '/api/wishes/activity' }
        }[activityType];
    };

    // Generic function to fetch data from an API endpoint with given parameters
    const fetchData = async (endpoint, params) => {
        try {
            const response = await axios.get(endpoint, { params });
            return response.data;
        } catch (error) {
            console.error(`Error fetching data from ${endpoint}:`, error);
            throw error;  // Re-throw error to be handled by the caller
        }
    };

    // Fetch summary and items based on activity type
    useEffect(() => {
        const initFetch = async () => {
            setIsLoading(true);

            try {
                // Fetch summary data
                const summaryData = await fetchData('/api/summary/activity', { userId });
                setSummary(summaryData);

                // Fetch activity items if an activity type is selected
                if (activityType) {
                    const { status, owner, endpoint } = getActivityConfig(activityType);
                    const itemsData = await fetchData(endpoint, { userId, status, owner });
                    setItems(itemsData);
                    console.log('Activity items fetched:', itemsData);
                }
            } catch (error) {
                console.error('Error during initial fetch:', error);
            } finally {
                setIsLoading(false);
                if (shouldUpdate) {
                    setShouldUpdate(false); // Reset shouldUpdate to prevent re-fetching unless explicitly set again
                }
            }
        };

        initFetch();
    }, [userId, activityType, shouldUpdate]);  // Trigger fetch on changes to these dependencies

    // Handle dashboard card clicks to set current view and activity type
    const handleDashboardClick = (view) => {
        if (currentView === view) {
            setCurrentView('');
            setActivityType('');  // Reset activity type temporarily
            setItems([]);  // Clear items
            setAllViewsCollapsed(true);
        } else {
            setCurrentView(view);
            setAllViewsCollapsed(false);
            setItems([]);  // Clear items before setting new activity type
            const activityTypes = {
                'shares': 'approveInterests',
                'requests': 'requestsApproved',
                'transactions': 'transactedReceived',
                'wishlist': 'wishesMatched'
            };
            const newActivityType = activityTypes[view];
            setActivityType(newActivityType);  // Set new activity type which will trigger useEffect
            setRadioValue(newActivityType);
        }
    };

    // Handle radio button changes within the current view
    const handleRadioChange = (newValue) => {
        if (containerRef.current) {
            const currentScroll = containerRef.current.scrollTop;
            sessionStorage.setItem('scrollPosition', currentScroll);
        }

        setRadioValue(newValue);
        setActivityType(newValue);
    };

    // Restore scroll position after items are loaded
    useEffect(() => {
        const timer = setTimeout(() => {
            if (containerRef.current) {
                const savedScroll = sessionStorage.getItem('scrollPosition');
                if (savedScroll) {
                    containerRef.current.scrollTop = parseInt(savedScroll, 10);
                }
            }
        }, 100); // Delay might need adjusting based on actual render times

        return () => clearTimeout(timer);
    }, [items]);

    // Handle item deletion
    const handleItemDeleted = (deletedItemId) => {
        setItems(currentItems => currentItems.filter(item => item.id !== deletedItemId));
        setShouldUpdate(true);  // Indicate that an update has occurred that may affect notifications
        fetchNotifications();    // Refresh notifications from context
    };

    // Handle status updates for items
    const handleStatusUpdate = (updatedItemId, newStatus) => {
        console.log(`Updating status for item ${updatedItemId} to ${newStatus}`);
        setItems(currentItems => {
            const updatedItems = currentItems.map(item => {
                if (item.id === updatedItemId) {
                    return { ...item, status: newStatus };
                }
                return item;
            });
            setShouldUpdate(true); // Signal that an update has occurred
            return updatedItems;
        });
        fetchNotifications(); // Refresh notifications from context
    };

    // Display a loading state while fetching data
    if (isLoading) {
        return <div>Loading...</div>;
    }

    return (
        <div className="container2 mt-4 position-relative" ref={containerRef}>
            {/* Dashboard Overview */}
            <div className="dashboard-overview mb-3 p-2 bg-light border rounded d-flex flex-wrap">
                {/* My Shared Items Card */}
                {!currentView || currentView === 'shares' ? (
                    <div 
                        className={`card text-center p-2 flex-fill m-1 clickable ${currentView === 'shares' ? 'selected' : ''}`} 
                        onClick={() => handleDashboardClick('shares')}
                    >
                        <h6 className="mb-1">
                            <FontAwesomeIcon icon={faUserFriends} /> Shared Items
                        </h6>
                        {notifications.approve_interests > 0 && (
                            <span className="notification-badge">{notifications.approve_interests}</span>
                        )}
                        <div className="lead">{summary.itemsWithInterest || 0} Interested</div>
                        <small>{summary.totalShares || 0} Total</small>
                    </div>
                ) : null}

                {/* My Requests Card */}
                {!currentView || currentView === 'requests' ? (
                    <div 
                        className={`card text-center p-2 flex-fill m-1 clickable ${currentView === 'requests' ? 'selected' : ''}`} 
                        onClick={() => handleDashboardClick('requests')}
                    >
                        <h6 className="mb-1">
                            <FontAwesomeIcon icon={faCheckCircle} /> Requests
                        </h6>
                        {notifications.approved_new_requests > 0 && (
                            <span className="notification-badge">{notifications.approved_new_requests}</span>
                        )}
                        <div className="lead">{summary.approvedRequests || 0} Accepted</div>
                        <small>{summary.totalRequests || 0} Total</small>
                    </div>
                ) : null}

                {/* My Transactions Card */}
                {!currentView || currentView === 'transactions' ? (
                    <div 
                        className={`card text-center p-2 flex-fill m-1 clickable ${currentView === 'transactions' ? 'selected' : ''}`} 
                        onClick={() => handleDashboardClick('transactions')}
                    >
                        <h6 className="mb-1">
                            <FontAwesomeIcon icon={faExchangeAlt} /> Transactions
                        </h6>
                        <div className="lead">{summary.itemsReceived || 0} Received</div>
                        <small>{summary.itemsGiven || 0} Given</small>
                    </div>
                ) : null}

                {/* My Wishlist Card */}
                {!currentView || currentView === 'wishlist' ? (
                    <div 
                        className={`card text-center p-2 flex-fill m-1 clickable ${currentView === 'wishlist' ? 'selected' : ''}`} 
                        onClick={() => handleDashboardClick('wishlist')}
                    >
                        <h6 className="mb-1">
                            <FontAwesomeIcon icon={faList} /> Wishlist
                        </h6>
                        {notifications.new_matching_wishlist > 0 && (
                            <span className="notification-badge">{notifications.new_matching_wishlist}</span>
                        )}
                        <div className="lead">{summary.wishesMatched || 0} Matched</div>
                        <small>{summary.wishesTotal || 0} Total</small>
                    </div>
                ) : null}
            </div>

            {/* Activity Controls */}
            <div className="activity-controls">
                {currentView && (
                    <>
                        {/* Shares View Controls */}
                        {currentView === 'shares' && (
                            <>
                                <button 
                                    className={`activity-button ${radioValue === 'approveInterests' ? 'active' : ''}`}
                                    onClick={() => handleRadioChange('approveInterests')}
                                >
                                    Approve Interests
                                    {notifications.approve_interests > 0 && (
                                        <span className="notification-badge">{notifications.approve_interests}</span>
                                    )}
                                </button>
                                <button 
                                    className={`activity-button ${radioValue === 'pendingTransfers' ? 'active' : ''}`}
                                    onClick={() => handleRadioChange('pendingTransfers')}
                                >
                                    Pending Transfers
                                </button>
                                <button 
                                    className={`activity-button ${radioValue === 'pendingInterests' ? 'active' : ''}`}
                                    onClick={() => handleRadioChange('pendingInterests')}
                                >
                                    Awaiting Interests
                                </button>
                            </>
                        )}

                        {/* Requests View Controls */}
                        {currentView === 'requests' && (
                            <>
                                <button 
                                    className={`activity-button ${radioValue === 'requestsApproved' ? 'active' : ''}`}
                                    onClick={() => handleRadioChange('requestsApproved')}
                                >
                                    Approved Requests
                                    {notifications.approved_new_requests > 0 && (
                                        <span className="notification-badge">{notifications.approved_new_requests}</span>
                                    )}
                                </button>
                                <button 
                                    className={`activity-button ${radioValue === 'requestsAwaiting' ? 'active' : ''}`}
                                    onClick={() => handleRadioChange('requestsAwaiting')}
                                >
                                    Pending Requests
                                </button>
                            </>
                        )}

                        {/* Transactions View Controls */}
                        {currentView === 'transactions' && (
                            <>
                                <button 
                                    className={`activity-button ${radioValue === 'transactedReceived' ? 'active' : ''}`}
                                    onClick={() => handleRadioChange('transactedReceived')}
                                >
                                    Items I Received
                                </button>
                                <button 
                                    className={`activity-button ${radioValue === 'transactedShared' ? 'active' : ''}`}
                                    onClick={() => handleRadioChange('transactedShared')}
                                >
                                    Items I Gave
                                </button>
                            </>
                        )}

                        {/* Wishlist View Controls */}
                        {currentView === 'wishlist' && (
                            <>
                                <button 
                                    className={`activity-button ${radioValue === 'wishesMatched' ? 'active' : ''}`}
                                    onClick={() => handleRadioChange('wishesMatched')}
                                >
                                    Matched
                                    {notifications.new_matching_wishlist > 0 && (
                                        <span className="notification-badge">{notifications.new_matching_wishlist}</span>
                                    )}
                                </button>
                                <button 
                                    className={`activity-button ${radioValue === 'wishesUnmatched' ? 'active' : ''}`}
                                    onClick={() => handleRadioChange('wishesUnmatched')}
                                >
                                    Unmatched
                                </button>
                            </>
                        )}
                    </>
                )}
            </div>

            {/* Display Items or Wishlist */}
            {currentView && currentView !== 'wishlist' && (
                <div className="row mt-3">
                    <div className="d-flex flex-wrap justify-content-around">
                        {items.length > 0 ? (
                            items.map(item => (
                                <ItemCard key={item.id} item={item} onItemSelect={() => setSelectedItem(item)} />
                            ))
                        ) : (
                            <div className="text-center p-3">No items to show for this category</div>
                        )}
                    </div>
                </div>
            )}
            {currentView === 'wishlist' && (
                <div className="row mt-3">
                    <div className="d-flex flex-wrap justify-content-around">
                        {items.length > 0 ? (
                            items.map(item => (
                                <WishListRow key={item.id} wishlist={item} onItemSelect={() => setSelectedItem(item)} />
                            ))
                        ) : (
                            <div className="text-center p-3">No items to show for this category</div>
                        )}
                    </div>
                </div>
            )}

            {/* Item Details Modal */}
            {currentView !== 'wishlist' && selectedItem && (
                <ItemDetailsModal
                    item={selectedItem}
                    onClose={() => setSelectedItem(null)}
                    onDelete={handleItemDeleted}
                    onStatusUpdate={handleStatusUpdate}
                    isOwner={userId === selectedItem.owner_id}
                />
            )}

            {/* Wish Details Modal */}
            {currentView === 'wishlist' && selectedItem && (
                <WishDetailsModal
                    item={selectedItem}
                    onClose={() => setSelectedItem(null)}
                    onDelete={handleItemDeleted}
                    isOwner={userId === selectedItem.owner_id}
                />
            )}
        </div>
    );
}

export default MyActivity;
